import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import ReactPaginate from 'react-paginate'
import API from '../services/api'
import PlanningRow from "./PlanningRow"
import './PlanningTable.scss'

const PlanningTable = ({componentName, shareid}) => {
  const [rows, setRows] = useState([])
  const [pagination, setPagination] = useState({PageSize: 15})

  const handlePageChange = (e) => {
    const pageNumber = e.selected !== undefined ? e.selected + 1 : e
    API.get('k12mealcomponents', !!shareid ? shareid : '', `Search=${componentName}&PageNumber=${pageNumber}&PageSize=${pagination.PageSize}`)
      .then(response => {
        if (response.ok) {
          const page = JSON.parse(response.headers.get("X-Pagination"))
          setPagination(page)
          response.json()
            .then(json => {
              setRows(json)
            })
        }
      })
  }

  const handleItemChanged = (item, field, value) => {
    let tracker = {...item.tracker}
    tracker[field] = value
    tracker.mealComponentId = item.meal.mealComponentId
    API.post('k12mealcomponents', tracker)
    const newrows = rows.map(r => {
      if (r.meal.mealComponentId === item.meal.mealComponentId){
        return {
          meal: r.meal,
          tracker: tracker
        }
      }
      return r
    })
    setRows(newrows)
  }

  useEffect(() => {
    handlePageChange(1)
  },[])

  return (
    <Table bordered>
      <thead>
        <tr style={{
          backgroundColor: componentName === 'Fruits' ? '#f7d2d2' :'#abf9a7'
        }}>
          <th colSpan={10}>{componentName}</th>
          <th colSpan={3}>{!!pagination.CurrentPage &&
            <nav aria-label="Page navigation food items" >
              <ReactPaginate
                className="pagination"
                breakLabel='...'
                nextLabel='next >'
                onPageChange={handlePageChange}
                pageRangeDisplayed={5}
                pageCount={pagination.TotalPages}
                previousLabel='< previous'
                renderOnZeroPageCount={null}
                breakClassName="page-item"
                breakLinkClassName="page-link"
                marginPagesDisplayed={2}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
              />
            </nav>
          }</th>
        </tr>
        <tr style={{
          backgroundColor: componentName === 'Fruits' ? '#f7d2d2' :'#abf9a7'
        }}>
          <th colSpan={12}>PLANNED</th>
          <th colSpan={3}>ACTUAL</th>
        </tr>
        <tr className="text-center">
          <th>Identifier<br/>(optional)</th>
          <th style={{
            minWidth: '180px'
          }}>Food as Purchased</th>
          <th>Purchase Unit</th>
          <th style={{
            minWidth: '180px'
          }}>Produce Preparation</th>
          <th>Number of Servings</th>
          <th>Serving Size</th>
          <th>Estimated Purchase Amount</th>
          <th>Weight per One Serving</th>
          <th>Estimated Weight of Edible Portion</th>
          <th>Amount Actually Purchased</th>
          <th>Weight of Edible Portion</th>
          <th>Total Volume Prepared</th>
          <th>Last Edited</th>
        </tr>
      </thead>
      <tbody>
          {!!rows &&
            rows.map((r,i) => <PlanningRow key={i}
              row={r}
              onItemChanged={handleItemChanged}
              disable={!!shareid}
              />)
          }
      </tbody>
    </Table>
  )
}

export default PlanningTable