import React, { useEffect } from "react"
import { Button, Col, Container, Row } from "reactstrap"
import { useNavigate  } from 'react-router-dom'
import AdRotator from "./AdRotator"
import useUser from '../hooks/useUser'
import "./Home.scss"
import API from "../services/api"
import homeimage from '../assets/homebase.jpg'
import k12logo from '../assets/K12 Culinary Team Logo (with website) EPS TO JPEG.jpg'

const Home = () => {
  const navigate = useNavigate()
  const { currentUser, setUser } = useUser()

  const handleTeamCalculatorClick = () => {
    navigate('/teamcalculator')
  }

  useEffect(() => {
    if (!currentUser?.isAuthSuccessful) {
      API.get('authentication')
        .then(response => {
          if (response.ok){
            response.json()
              .then(json => {
                setUser(json)
              })
          }
        })
    }
  },[])

  return (
    <Container fluid>
      <img src={homeimage} className='image-base' />
      <Row>
        <Col xs={0} sm={0} lg={2} xl={3} />
        <Col xs={12} sm={12} lg={9} xl={6}>
          <Container fluid>
            <Row>
              <Col className="mb-1 text-center">
                <Button color="primary" className="btn-primary-landing" onClick={handleTeamCalculatorClick}>
                  K-12 Team Calculator
                  <img src={k12logo} width='125px' />
                </Button>
              </Col>
              <Col className="mb-1 text-center">
                <Button color="primary" className="btn-primary-landing">Salad Bar Tool<br/><span className="smaller">- Coming Soon!</span></Button>
              </Col>
              <Col className="mb-1 text-center">
                <Button color="primary" className="btn-primary-landing">Yield Finder Tool<br/><span className="smaller">- Coming Soon!</span></Button>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col sx={12} sm={12} lg={1}>
          <Container fluid>
            <Row>
              <Col>
                <AdRotator />
              </Col>
            </Row>
            <Row>
              <Col>
                <AdRotator />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default Home