import {useState} from 'react'

const useSessionStorage = (keyName, defaultValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = window.sessionStorage.getItem(keyName)
            if (value) {
                return JSON.parse(value)
            }
            window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue))
            return defaultValue
        } catch {
            return defaultValue
        }
    })

    const setValue = (newValue) => {
        window.sessionStorage.setItem(keyName, JSON.stringify(newValue))
        setStoredValue(newValue)
    }

    const getValue = () => {
        var json = window.sessionStorage.getItem(keyName)
        return JSON.parse(json)
    }

    return [storedValue, setValue, getValue]
}

export default useSessionStorage