import React, { useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { Button, Col, Container, Form, Input, Label, Row } from "reactstrap"
import API from "../services/api"

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        const body = {
            email: email
        }
        const response = await API.post('authentication',body,'forgotpassword')
        if (response.ok) {
            setSubmitted(true)
        } else {
            toast.error('Your request failed. Please try again later.')
        }
    }

    return (
        <Container fluid>
            {!submitted &&
            <Row>
                <Col xs={0} sm={0} lg={2} />
                <Col xs={12} sm={12} lg={8}>
                    <Form onSubmit={handleSubmit}>
                        <Label for='email'>Enter your email address.</Label>
                        <Input id='email' name='email' type='email' required value={email} onChange={(e) => setEmail(e.target.value)} />
                        <Button type='submit'>Send</Button>
                    </Form>
                </Col>
                <Col xs={0} sm={0} lg={2} />
            </Row>
            }
            {submitted &&
            <Row>
                <Col xs={0} sm={0} lg={2} />
                <Col xs={12} sm={12} lg={8} className='text-center'>
                    Your request has be submitted. If we find an email address that matches yours you will recieve a password update link.<br />
                    <Link to='/login'>Return to login</Link>
                </Col>
                <Col xs={0} sm={0} lg={2} />
            </Row>
            }
        </Container>
    )
}

export default ForgotPassword