import React, { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Button, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Row } from "reactstrap"
import { toast } from 'react-toastify'
import API from "../services/api"
import zxcvbn from "zxcvbn"
import { throttle } from "lodash"
import Email from "../services/email"

const Register = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [position, setPosition] = useState('')
    const [returnLink, setReturnLink] = useState()
    const [orgType, setOrgType] = useState()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [orgTypes, setOrgTypes] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [passwordMessage, setPasswordMessage] = useState('Password not strong enough.')
    const [passwordClass, setPasswordClass] = useState('pw-bad-message')
    const [pwscore, setPWScore] = useState(0)
    const [isEmailAvailable, setIsEmailAvailable] = useState(false)
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [emailClass, setEmailClass] = useState('pw-good-message')
    const [emailMessage, setEmailMessage] = useState('')

    const handleRegisterSubmit = async (e) => {
        e.preventDefault()
        const body = {
            firstName: firstName,
            lastName: lastName,
            userName: email,
            password: password,
            email: email,
            organizationTypeId: orgType.organizationTypeId,
            position: position
        }
        const response = await API.post('authentication', body)
        if (response.ok) {
            toast('You have registered.')
            if (returnLink) {
                navigate(`/login?r=${returnLink}`)
            } else {
                navigate('/login')
            }
        } else {
            toast.error('An error occurred when registering.')
        }
    }

    const handlePasswordChange = (e) => {
        const pw = e.target.value;
        setPassword(pw)
        const score = zxcvbn(pw)
        setPWScore(score.score)
        if (score.score < 3) {
            setPasswordMessage('Password not strong enough.')
            setPasswordClass('pw-bad-message')
        } else {
            setPasswordMessage('Good password.')
            setPasswordClass('pw-good-message')
        }
    }

    const getOrganizationTypes = () => {
        API.get('lookup','organizationtypes')
            .then(response => {
                if (response.ok) {
                    response.json()
                        .then(json => {
                            setOrgTypes(json)
                            setOrgType(json[0])
                        })
                }
            })
    }

    const handleOrgSelect = (ot) => {
        setOrgType(ot)
    }

    const toggle = () => setIsOpen(!isOpen)

    const testEmailAvailability = () => {
        if (!Email.isValid(email)) return
        const body = {
            email: email
        }
        API.post('authentication', body, 'isavailable')
            .then(response => {
                if (response.ok) {
                    response.json()
                        .then(json => {
                            setIsEmailAvailable(json.isAvailable)
                            if (json.isAvailable) {
                                setEmailClass('pw-good-message')
                                setEmailMessage('This email address is available.')
                            } else {
                                setEmailClass('pw-bad-message')
                                setEmailMessage('This email address is not available. Please log in or choose a different email.')
                            }
                        })
                } else {
                    setIsEmailAvailable(false)
                    setEmailClass('pw-bad-message')
                    setEmailMessage('This email address is not available. Please log in or choose a different email.')
                }
            }).catch(e => {
                if (!!e.message) {
                    toast.error(e.message)
                } else {
                    toast.error("An unexpected error has occurred.")
                }
                setIsEmailAvailable(false)
                setEmailClass('pw-bad-message')
                setEmailMessage('Unexpected error.')
            })
    }

    const throttledEmail = throttle(testEmailAvailability, 750)

    useEffect(() => {
        setReturnLink(searchParams.get('r'))
        getOrganizationTypes()
    },[])

    useEffect(() => {
        const isValid = Email.isValid(email)
        setIsEmailValid(isValid)
        if (isValid) {
            throttledEmail()
        } else {
            setEmailClass('pw-bad-message')
            setEmailMessage('Invalid email format.')
        }
        return throttledEmail.cancel
    },[email])

    return (
        <Container fluid>
            <Row>
                <Col xs={0} sm={0} lg={3}/>
                <Col xs={12} sm={12} lg={6}>
                    <Form onSubmit={handleRegisterSubmit}>
                        <Label for="firstName">First Name</Label>
                        <Input required name="firstName" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        <Label for="lastName">Last Name</Label>
                        <Input required name="lastName" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        <Label for="email">Email</Label>
                        <Input required type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <span className={emailClass}>{emailMessage}</span><br />
                        <Label for="orgtype">Organization Type</Label>
                        <Dropdown id="orgtype" name="orgtype" toggle={toggle} isOpen={isOpen}>
                            <DropdownToggle caret>{!!orgType && orgType.typeName}</DropdownToggle>
                            <DropdownMenu>
                                {!!orgTypes &&
                                    orgTypes.map((o,i) => {
                                        return (
                                            <DropdownItem key={i} onClick={()=>handleOrgSelect(o)}>{o.typeName}</DropdownItem>
                                        )
                                    })
                                }
                            </DropdownMenu>
                        </Dropdown>
                        <Label for="position">Position</Label>
                        <Input id="position" name="position" value={position} onChange={(e) => setPosition(e.target.value)} />
                        <Label for="password">Password</Label>
                        <Input required type="password" name="password" id="password" value={password} onChange={handlePasswordChange} /><br />
                        <span className={passwordClass}>{passwordMessage}</span><br />
                        <Button type="submit" disabled={pwscore < 3 || !isEmailValid || !isEmailAvailable}>Register</Button>
                    </Form>
                </Col>
                <Col xs={0} sm={0} lg={3}/>
            </Row>
        </Container>
    )
}

export default Register