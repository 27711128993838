import React, { useEffect, useState } from "react"
import { Link, useSearchParams, useNavigate } from "react-router-dom"
import { Row, Col, Container, Form, Input, Label, Button } from "reactstrap"
import { toast } from 'react-toastify'
import API from "../services/api"
import useUser from '../hooks/useUser'

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [searchParams] = useSearchParams()
    const [returnLink, setReturnLink] = useState()
    const navigate = useNavigate()
    const {setUser} = useUser()

    const handleLoginSubmit = async (e) => {
        e.preventDefault()
        const login = {
            UserName: email,
            Password: password
        }
        const response = await API.post('authentication', login, 'login')
        if (response.ok) {
            toast('You have logged in.')
            const token = await response.json()
            setUser(token)
            if (returnLink) {
                navigate(returnLink)
            } else {
                navigate('/')
            }
        } else {
            toast.error('Invalid username or password.')
        }
    }

    useEffect(() => {
        setReturnLink(searchParams.get('r'))
    },[])

    return (
        <Container fluid>
            <Row>
                <Col xs={0} sm={0} lg={3}/>
                <Col xs={12} sm={12} lg={6}>
                    <Form onSubmit={handleLoginSubmit}>
                        <Label for='email'>Email Address</Label>
                        <Input type="email"
                            name="email"
                            id="email"
                            value={email}
                            required
                            onChange={(e)=>setEmail(e.target.value)}/>
                        <Label for='password'>Password</Label>
                        <Input type='password'
                            name='password'
                            id='password'
                            value={password}
                            required
                            onChange={(e)=>setPassword(e.target.value)} />
                        <Button type="submit">Login</Button><br />
                        <Link to={!!returnLink ? `/register?r=${returnLink}`: '/register'}>Not a user? Register here.</Link><br />
                        <Link to='/forgotpassword'>Forgot password?  Click here.</Link>
                    </Form>
                </Col>
                <Col xs={0} sm={0} lg={3}/>
            </Row>
        </Container>
    )
}

export default Login