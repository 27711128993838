import { faClipboard } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { toast } from "react-toastify"
import { Button, Input, Label, Modal, ModalBody, ModalFooter, Tooltip } from "reactstrap"

const LinkModal = ({link, isOpen, toggle}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const handleCopyClick = async () => {
        await navigator.clipboard.writeText(link);
        toast.success('Link copied to your clipboard.')
    }

    return (
        <Modal isOpen={isOpen}
            toggle={toggle}>
            <ModalBody>
                <Label for='linkurl'>Your link is ready to share.</Label>
                <Input id='linkurl' name='linkurl' readOnly={true} value={link} />
                <Button id='copybutton' onClick={handleCopyClick}><FontAwesomeIcon icon={faClipboard} /></Button>
                <Tooltip target='copybutton'
                    placement="bottom"
                    isOpen={tooltipOpen}
                    toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                        Copy to clipboard.
                </Tooltip>
            </ModalBody>
            <ModalFooter>
                <Button type="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}

export default LinkModal