import React, { useEffect, useState } from "react"
import './Header.css'
import logo from '../assets/New BERY Logo.jpeg'
import k12logo from '../assets/K12 Culinary Team Logo (with website) EPS TO JPEG.jpg'
import { Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
import useUser from "../hooks/useUser"
import API from "../services/api"

const Header = () => {
  const {currentUser, clearUser} = useUser()
  const [isOpen, setIsOpen] = useState()
  const [isK12, setIsK12] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const handleLogoff = () => {
    clearUser()
    API.delete('authentication', 'login')
  }

  const handleProfilePicture = () => {
    window.open('https://gravatar.com', '_blank')
  }

  const handleAccountClick = () => {
    navigate('/account')
  }

  useEffect(() => {
    setIsK12(location.pathname.includes('teamcalculator'))
  }, [location])

  return (
    <Container fluid className="site-header">
      <Row>
        <Col xs={0} sm={0} lg={3} />
        <Col xs={1} sm={1} lg={1}>
          <Link to={'/'}>
            <img src={logo} height="140px" alt='BERY Logo' />
          </Link>
        </Col>
        <Col xs={10} sm={10} lg={4} className='text-center'>
          <h1>TECH TOOLS</h1>
          <span>Building Efficient Recipe Yields</span>
        </Col>
        <Col xs={0} sm={0} lg={1}>
          {isK12 &&
            <Link to={'/'}>
              <img src={k12logo} height="140px" alt='K12 Logo' />
            </Link>
          }   
        </Col>
        <Col xs={0} sm={0} lg={1} />
        <Col xs={2} sm={2} lg={2} className='text-left'>
          {!currentUser.userId &&
            <Link to={'/login'}>Login</Link>
          }
          {!!currentUser.userId &&
            <div>
              <img src={`https://www.gravatar.com/avatar/${currentUser.avatar}?d=mp`} alt='User avatar' />
              <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <DropdownToggle>Welcome, {currentUser.firstName}!</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={handleLogoff}>Log off</DropdownItem>
                  <DropdownItem onClick={handleAccountClick}>Manage Account</DropdownItem>
                  <DropdownItem onClick={handleProfilePicture}>Profile Picture - External</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default Header