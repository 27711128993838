import React, { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { Button, Col, Container, Form, Input, Label, Row } from "reactstrap"
import API from "../services/api"
import zxcvbn from "zxcvbn"

const ResetPassword = () => {
    const [password, setPassword] = useState('')
    const [token, setToken] = useState()
    const [passwordMessage, setPasswordMessage] = useState('Password not strong enough.')
    const [passwordClass, setPasswordClass] = useState('pw-bad-message')
    const [pwscore, setPWScore] = useState(0)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        const body = {
            password: password,
            token: token
        }
        const response = await API.post('authentication', body, 'resetpassword')
        if (response.ok) {
            toast.success('Your password has been reset.')
            navigate('/login')
        } else {
            toast.error('Something went wrong. Please try again later.')
        }
    }

    
    const handlePasswordChange = (e) => {
        const pw = e.target.value;
        setPassword(pw)
        const score = zxcvbn(pw)
        setPWScore(score.score)
        if (score.score < 3) {
            setPasswordMessage('Password not strong enough.')
            setPasswordClass('pw-bad-message')
        } else {
            setPasswordMessage('Good password.')
            setPasswordClass('pw-good-message')
        }
    }

    useEffect(() => {
        const t = searchParams.get('t')
        if (!t) {
            navigate('/')
        } else {
            setToken(t)
        }
    },[])

    return (
        <Container fluid>
            <Row>
                <Col sx={0} sm={0} lg={2} />
                <Col sx={12} sm={12} lg={8}>
                    <Form onSubmit={handleSubmit}>
                        <Label for='password'>Enter your new password.</Label>
                        <Input type='password' value={password} id='password' name='password' onChange={handlePasswordChange} required /><br />
                        <span className={passwordClass}>{passwordMessage}</span><br />
                        <Button type='submit' disabled={pwscore < 3}>Set Password</Button>
                    </Form>
                </Col>
                <Col sx={0} sm={0} lg={2} />
            </Row>
        </Container>
    )
}

export default ResetPassword