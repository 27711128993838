import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Row, Col, Button, Tooltip } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faShareNodes } from '@fortawesome/free-solid-svg-icons'
import useUser from '../hooks/useUser'
import PlanningTable from "./PlanningTable"
import TrackerShareModal from "./TrackerShareModal"
import LinkModal from './LinkModal'
import API from "../services/api"
import { toast } from "react-toastify"
import './TeamCalculator.scss'
import Files from "../services/files"

const TeamCalculator = () => {
    var navigate = useNavigate()
    const {currentUser} = useUser()
    const [categories, setCategories] = useState()
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const [shareTooltipOpen, setShareTooltipOpen] = React.useState(false);
    const [isShareOpen, setIsShareOpen] = useState(false)
    const [isLinkOpen, setIsLinkOpen] = useState(false)
    const [shareLink, setShareLink] = useState('')
    const params = useParams()

    const handleDownloadClick = () => {
        toast('Downloading your file.')
        const uri = !params.shareid ? '/print/k12' : `/print/k12/${params.shareid}`
        Files.download(uri, 'K12 Team Calculator.pdf')
            .then(e => toast.success('Download finished.'))
    }

    const handleSendClick = async (tracker) => {
        const body = {
            description: tracker.description,
            emails: tracker.sendEmails ? tracker.emailList : null,
            expiresAt: tracker.neverExpires ? null : tracker.expiresAt,
            customMessage: tracker.customMessage
        }
        const response = await API.post('k12mealcomponents',body,'shares')
        if (response.ok){
            if (tracker.sendEmails) {
                toast.success('We have invited your colleagues to view your calulator.')
            } else {
                toast.success('A link to your calculator has been generated.')
                const json = await response.json()
                setShareLink(json.url)
                setIsLinkOpen(true)
            }
        }
        setIsShareOpen(false);
    }

    useEffect(() => {
        if (!currentUser.userId) {
            if (!!params.shareid) {
                navigate(`/login?r=/teamcalculator/${params.shareid}`)
            } else {
                navigate('/login?r=/teamcalculator')
            }
        } else {
            setCategories(['Fruits', 'Vegetables'])
        }
    },[])

    return (
        <Container fluid>
            <Row>
                <Col xs={1} sm={1} lg={1}/>
                <Col className='text-center'><h1>Welcome to the K-12 Team Calculator</h1></Col>
                <Col xc={1} sm={1} lg={1} className='text-center'>
                    <Button id='downloadButton' onClick={handleDownloadClick}><FontAwesomeIcon icon={faDownload} /></Button>
                    <Tooltip target='downloadButton'
                        placement="bottom"
                        isOpen={tooltipOpen}
                        toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                            Download items.
                    </Tooltip>
                    {!params.shareid &&
                        <>
                            <Button id='shareButton' onClick={() => setIsShareOpen(true)}><FontAwesomeIcon icon={faShareNodes} /></Button>
                            <Tooltip target='shareButton'
                                placement="bottom"
                                isOpen={shareTooltipOpen}
                                toggle={() => { setShareTooltipOpen(!shareTooltipOpen) }}>
                                    Share Calculator.
                            </Tooltip>
                        </>
                    }
                </Col>
            </Row>
            {!!categories &&
                categories.map((c,i) => <PlanningTable key={i} componentName={c} shareid={params.shareid} />)
            }
            <TrackerShareModal isOpen={isShareOpen} toggle={()=>setIsShareOpen(!isShareOpen)} onCancelClick={() =>setIsShareOpen(false)} onSendClick={handleSendClick} />
            <LinkModal isOpen={isLinkOpen} toggle={()=>setIsLinkOpen(!isLinkOpen)} link={shareLink} />
        </Container>
    )
}

export default TeamCalculator