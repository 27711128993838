import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { Button, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Row } from "reactstrap"
import { toast } from 'react-toastify'
import useUser from "../hooks/useUser"
import API from "../services/api"

const Account = () => {
  const navigate = useNavigate()
  const {currentUser, setUser} = useUser()
  const [firstName, setFirstName] = useState(currentUser.firstName)
  const [lastName, setLastName] = useState(currentUser.lastName)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [position, setPosition] = useState(currentUser.position)
  const [orgType, setOrgType] = useState()
  const [orgTypes, setOrgTypes] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const getOrganizationTypes = () => {
    API.get('lookup','organizationtypes')
        .then(response => {
            if (response.ok) {
                response.json()
                    .then(json => {
                        setOrgTypes(json)
                        if (!!currentUser.organizationTypeId) {
                          setOrgType(json.filter(o => o.organizationTypeId === currentUser.organizationTypeId)[0])
                        } else {
                          setOrgType(json[0])
                        }
                    })
            }
        })
  }

  const handleUpdateSubmit = async (e) => {
    e.preventDefault()
    const body = {
      firstName: firstName,
      lastName: lastName,
      password: newPassword,
      oldPassword: password,
      organizationTypeId: orgType.organizationTypeId,
      position: position
    }
    let response = await API.put('authentication', body)
    if (response.ok) {
        toast('Update succesful.')
        response = await API.get('authentication')
        if (response.ok) {
          const json = await response.json()
          console.log(json);
          setUser(json)
        }
    } else {
        toast.error('An error occurred when updating.')
    }
  }

  const handleOrgSelect = (ot) => {
    setOrgType(ot)
  }

  const toggle = () => setIsOpen(!isOpen)

  useEffect(() => {
    if (!currentUser.userId) {
        navigate('/login?r=/account')
    } else {
      getOrganizationTypes()
    }
  },[])

  return (
    <Container fluid>
      <Row>
          <Col xs={0} sm={0} lg={3}/>
          <Col xs={12} sm={12} lg={6}>
              <Form onSubmit={handleUpdateSubmit}>
                  <Label for="firstName">First Name</Label>
                  <Input required name="firstName" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                  <Label for="lastName">Last Name</Label>
                  <Input required name="lastName" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                  <Label for="orgtype">Organization Type</Label>
                  <Dropdown id="orgtype" name="orgtype" toggle={toggle} isOpen={isOpen}>
                      <DropdownToggle caret>{!!orgType && orgType.typeName}</DropdownToggle>
                      <DropdownMenu>
                          {!!orgTypes &&
                              orgTypes.map((o,i) => {
                                  return (
                                      <DropdownItem key={i} onClick={()=>handleOrgSelect(o)}>{o.typeName}</DropdownItem>
                                  )
                              })
                          }
                      </DropdownMenu>
                  </Dropdown>
                  <Label for="position">Position</Label>
                  <Input id="position" name="position" value={position} onChange={(e) => setPosition(e.target.value)} />
                  <Label for="password">Old Password</Label>
                  <Input required={!!newPassword.length} type="password" name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  <Label for="newpassword">New Password</Label>
                  <Input type="password" name="newpassword" id="newpassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                  <Button type="submit">Update</Button>
              </Form>
          </Col>
          <Col xs={0} sm={0} lg={3}/>
      </Row>
    </Container>
  )
}

export default Account