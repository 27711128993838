import React, { useEffect, useState } from "react"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap"
import { format } from 'date-fns'

const PlanningRow = ({row, onItemChanged, disable}) => {
  const [isMethodOpen, setIsMethodOpen] = useState(false)
  const [isSizeOpen, setIsSizeOpen] = useState(false)
  const [isMajorOpen, setIsMajorOpen] = useState(false)
  const [isMinorOpen, setIsMinorOpen] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [identifier, setIdentifier] = useState(!!row.tracker?.identifier ? row.tracker.identifier : '')
  const [numberOfServings, setNumberOfServings] = useState(!!row.tracker?.numberOfServings ? row.tracker.numberOfServings : '')
  const [actuallyPurchasedLbs, setActuallyPurchasedLbs] = useState(!!row.tracker?.actuallyPurchasedLbs ? row.tracker.actuallyPurchasedLbs : '')
  const [actuallyPurchasedOz, setActuallyPurchasedOz] = useState(!!row.tracker?.actuallyPurchasedOz ? row.tracker.actuallyPurchasedOz : '')
  const [ediblePortionLbs, setEdiblePortionLbs] = useState(!!row.tracker?.ediblePortionLbs ? row.tracker.ediblePortionLbs : '')
  const [ediblePortionOz, setEdiblePortionOz] = useState(!!row.tracker?.ediblePortionOz ? row.tracker.ediblePortionOz : '')
  const [totalVolumePrepared, setTotalVolumePrepared] = useState(!!row.tracker?.totalVolumePrepared ? row.tracker.totalVolumePrepared : '')
  const [preparedUnitMajor, setPreparedUnitMajor] = useState(!!row.tracker?.preparedUnitMajor ?  row.tracker.preparedUnitMajor : '')
  const [preparedUnitMinor, setPreparedUnitMinor] = useState(!!row.tracker?.preparedUnitMinor ?  row.tracker.preparedUnitMinor : '')
  const [totalPreparedMinor, setTotalPreparedMinor] = useState(!!row.tracker?.totalPreparedMinor ?  row.tracker.totalPreparedMinor : '')

  const calculateEstimatedPurchase = (row) => {
    const factor = row.meal.servingSize / row.tracker.servingSize
    let amount = row.meal.servingsperPurchaseUnitEP * factor
    amount = row.tracker.numberOfServings / amount
    let oz = amount * 16
    amount = Math.floor(amount)
    oz -= amount * 16
    oz = Math.ceil(oz)
    oz = oz < 0 ? 0 : oz
    return `${amount} lb ${oz} oz`
  }

  const calculateWeightPerServing = (row) => {
    let amount = row.meal.servingsperPurchaseUnitEP
    const factor = row.meal.servingSize / row.tracker.servingSize
    const servingsPer = amount * factor
    let servingOz = Math.ceil(((1 / servingsPer) * 16) * 10) / 10
    return `${servingOz} oz`
  }

  const calculateEdibleWeightPerServing = (row) => {
    let amount = row.meal.servingsperPurchaseUnitEP
    const factor = row.meal.servingSize / row.tracker.servingSize
    const servingsPer = amount * factor
    let servingOz = Math.ceil(((1 / servingsPer) * 16) * 10) / 10
    return `${servingOz} oz`
  }


  const [estimatedPurchase, setEstimatedPurchase] = useState(!!row.tracker ? calculateEstimatedPurchase(row) : 'Calculated')
  const [servingWeight, setServingWeight] = useState(!!row.tracker ? calculateWeightPerServing(row) : 'Calculated')
  const [edibleWeight, setEdibleWeight] = useState(!!row.tracker ? calculateEdibleWeightPerServing(row) : 'Calculated')

  const getServingSize = (size) => {
    switch (size) {
      case 0.125:
        return '1/8 Cup'
      case 0.25:
        return '1/4 Cup'
      case 0.5:
        return '1/2 Cup'
      case 0.75:
        return '3/4 Cup'
      case 1:
        return '1 Cup'
      default:
        return 'Choose'
    }
  }

  const [servingSize, setServingSize] = useState(!!row.tracker ? getServingSize(row.tracker.servingSize) : 'Choose')

  const convertServingSize = (size) => {
    switch (size) {
      case '1/8 Cup':
        return 0.125
      case '1/4 Cup':
        return 0.25
      case '1/2 Cup':
        return 0.5
      case '3/4 Cup':
        return 0.75
      case '1 Cup':
        return 1
      default:
        return 0
    }
  }

  const toggleSizeOpen = () => setIsSizeOpen(!isSizeOpen)
  const toggleMajorOpen = () => setIsMajorOpen(!isMajorOpen)
  const toggleMinorOpen = () => setIsMinorOpen(!isMinorOpen)

  const handleItemChanged = () => {
    setIsChanged(true)
  }

  const handleItemBlur = (row, field, value, force) => {
    if (isChanged || force) {
      onItemChanged(row,field,value)
      setIsChanged(false)
    }
  }

  const handleServingSizeClick = (e, row) => {
    const size = convertServingSize(e.currentTarget.textContent)
    setServingSize(e.currentTarget.textContent)
    handleItemBlur(row, "servingSize", size, true)
  }

  const handlePreparedUnitMajorClick = (e,row)=> {
    const unit = e.currentTarget.textContent
    setPreparedUnitMajor(unit)
    handleItemBlur(row, 'preparedUnitMajor', unit, true)
  }

  const handlePreparedUnitMinorClick = (e,row)=> {
    const unit = e.currentTarget.textContent
    setPreparedUnitMinor(unit)
    handleItemBlur(row, 'preparedUnitMinor', unit, true)
  }

  useEffect(() => {
    setIdentifier(!!row.tracker?.identifier ? row.tracker.identifier : '')
    setNumberOfServings(!!row.tracker?.numberOfServings ? row.tracker.numberOfServings : '')
    setActuallyPurchasedLbs(!!row.tracker?.actuallyPurchasedLbs ? row.tracker.actuallyPurchasedLbs : '')
    setActuallyPurchasedOz(!!row.tracker?.actuallyPurchasedOz ? row.tracker.actuallyPurchasedOz : '')
    setEdiblePortionLbs(!!row.tracker?.ediblePortionLbs ? row.tracker.ediblePortionLbs : '')
    setEdiblePortionOz(!!row.tracker?.ediblePortionOz ? row.tracker.ediblePortionOz : '')
    setTotalVolumePrepared(!!row.tracker?.totalVolumePrepared ? row.tracker.totalVolumePrepared : '')
    setServingSize(!!row.tracker?.servingSize ? getServingSize(row.tracker.servingSize) : 'Choose')
    setEstimatedPurchase(!!row.tracker ? calculateEstimatedPurchase(row) : 'Calculated')
    setServingWeight(!!row.tracker ? calculateWeightPerServing(row) : 'Calculated')
    setEdibleWeight(!!row.tracker ? calculateEdibleWeightPerServing(row) : 'Calculated')
    setTotalPreparedMinor(!!row.tracker?.totalPreparedMinor ?  row.tracker.totalPreparedMinor : '')
    setPreparedUnitMajor(!!row.tracker?.preparedUnitMajor ?  row.tracker.preparedUnitMajor : '')
    setPreparedUnitMinor(!!row.tracker?.preparedUnitMinor ?  row.tracker.preparedUnitMinor : '')
  },[row])

  return (
    <tr>
      <td>
        <Input id="foodidentifier" name="foodidentifier" disabled={disable} value={identifier} onBlur={(e) => handleItemBlur(row, "identifier", e.target.value)} onChange={e => {setIdentifier(e.target.value); handleItemChanged()}} />
      </td>
      <td>
        {row.meal.foodAsPurchasedAP}
      </td>
      <td>
        {row.meal.purchaseUnit}
      </td>
      <td>
        {row.meal.servingSizePerMealContribution}
      </td>
      <td>
        <Input type="number" id="numservings" name="numservings" disabled={disable} min={0} value={numberOfServings} onBlur={e => handleItemBlur(row, "numberOfServings", e.target.value)} onChange={e => {setNumberOfServings(e.target.value); handleItemChanged()}} />
      </td>
      <td>
        <Dropdown toggle={toggleSizeOpen} isOpen={isSizeOpen} disabled={disable}>
          <DropdownToggle caret>{servingSize}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={(e) =>handleServingSizeClick(e,row)}>1/8 Cup</DropdownItem>
            <DropdownItem onClick={(e) =>handleServingSizeClick(e,row)}>1/4 Cup</DropdownItem>
            <DropdownItem onClick={(e) =>handleServingSizeClick(e,row)}>1/2 Cup</DropdownItem>
            <DropdownItem onClick={(e) =>handleServingSizeClick(e,row)}>3/4 Cup</DropdownItem>
            <DropdownItem onClick={(e) =>handleServingSizeClick(e,row)}>1 Cup</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </td>
      <td>
        <em>{estimatedPurchase}</em>
      </td>
      <td>
        <em>{servingWeight}</em>
      </td>
      <td>
        <em>{edibleWeight}</em>
      </td>
      <td>
        <Input min={0} type="number" disabled={disable} id="actpounds" name="actpounds" value={actuallyPurchasedLbs} onBlur={e => handleItemBlur(row, "actuallyPurchasedLbs", e.target.value)} onChange={e => {setActuallyPurchasedLbs(e.target.value); handleItemChanged()}} />lbs&nbsp;
        <Input min={0} type="number" disabled={disable} id="actounces" name="actounces" value={actuallyPurchasedOz} onBlur={e => handleItemBlur(row, "actuallyPurchasedOz", e.target.value)} onChange={e => {setActuallyPurchasedOz(e.target.value); handleItemChanged()}} />oz
      </td>
      <td>
        <Input min={0} type="number" disabled={disable} id="actweightpounds" name="actweightpounds" value={ediblePortionLbs} onBlur={e => handleItemBlur(row, "ediblePortionLbs", e.target.value)} onChange={e => {setEdiblePortionLbs(e.target.value); handleItemChanged()}} />lbs&nbsp;
        <Input min={0} type="number" disabled={disable} id="actweightounces" name="actweightounces" value={ediblePortionOz} onBlur={e => handleItemBlur(row, "ediblePortionOz", e.target.value)} onChange={e => {setEdiblePortionOz(e.target.value); handleItemChanged()}} />oz
      </td>
      <td>
        <Input min={0} type="number" disabled={disable} id="totalvolume" name="totalvolume" value={totalVolumePrepared} onBlur={e => handleItemBlur(row, "totalVolumePrepared", e.target.value)} onChange={e => {setTotalVolumePrepared(e.target.value); handleItemChanged()}} />
        <Dropdown toggle={toggleMajorOpen} disabled={disable} isOpen={isMajorOpen}>
          <DropdownToggle caret>{!!preparedUnitMajor ? preparedUnitMajor : 'Choose...'}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={(e) => handlePreparedUnitMajorClick(e,row)}>Gallon(s)</DropdownItem>
            <DropdownItem onClick={(e) => handlePreparedUnitMajorClick(e,row)}>Quart(s)</DropdownItem>
            <DropdownItem onClick={(e) => handlePreparedUnitMajorClick(e,row)}>Cup(s)</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <br />
        <Input min={0} type="number" disabled={disable} id="totalvolumeminor" name="totalvolumeminor" value={totalPreparedMinor} onBlur={e => handleItemBlur(row, "totalPreparedMinor", e.target.value)} onChange={e => {setTotalPreparedMinor(e.target.value); handleItemChanged()}} />
        <Dropdown toggle={toggleMinorOpen} isOpen={isMinorOpen} disabled={disable}>
          <DropdownToggle caret>{!!preparedUnitMinor ? preparedUnitMinor : 'Choose...'}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={(e) => handlePreparedUnitMinorClick(e,row)}>Quart(s)</DropdownItem>
            <DropdownItem onClick={(e) => handlePreparedUnitMinorClick(e,row)}>Cup(s)</DropdownItem>
            <DropdownItem onClick={(e) => handlePreparedUnitMinorClick(e,row)}>TBSP</DropdownItem>
            <DropdownItem onClick={(e) => handlePreparedUnitMinorClick(e,row)}>tsp</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </td>
      <td>
        {!!row.tracker && !!row.tracker.updatedAt ? format(new Date(row.tracker.updatedAt), 'M/d/yyyy hh:mm:ss a') : ''}
      </td>
    </tr>
  )
}

export default PlanningRow