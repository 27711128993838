import {createContext, useMemo} from 'react'
import useSessionStorage from '../hooks/useSessionStorage'

const initialUser = {
    userId: '',
    roles:[]
}

const initialState = {
  initialUser,
    setUser:() => {},
    clearUser:() => {},
    getUser:() => {}
}

const UserContext = createContext(initialState)

const UserProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useSessionStorage('current-user', initialUser)

    const setUser = (u) => {
        setCurrentUser(u)
    }

    const getUser = () => {
        return currentUser
    }

    const clearUser = () => {
        setCurrentUser({
            userName: "",
            roles:[],
            isLoggedIn: false
        })
    }

    const contextValue = useMemo(() => ({
        currentUser,
        setUser,
        clearUser,
        getUser
    }),[currentUser, setUser, clearUser, getUser])

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    )
}

export {UserContext, UserProvider}