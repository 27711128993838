const API = {
    apiVersion: 'v1',
    buildUri: (controller, route, query) => {
        let uri = `/api/${controller}/${API.apiVersion}`
        if (!!route) {
            uri = `${uri}/${route}`
        }
        if (!!query) {
            uri = `${uri}?${query}`
        }
        return uri
    },
    post: async (controller, body, route) => {
        const uri = API.buildUri(controller, route)
        const response = await fetch(uri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        return response
    },
    put: async (controller, body, route) => {
        const uri = API.buildUri(controller, route)
        const response = await fetch(uri, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        return response
    },
    get: async (controller, route, query) => {
        const uri = API.buildUri(controller, route, query)
        return await fetch(uri)
    },
    delete: async (controller, route) => {
        const uri = API.buildUri(controller, route)
        await fetch(uri, {
            method: 'DELETE'
        })
    }
}

export default API