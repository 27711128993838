import React, { useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import useUser from '../hooks/useUser'

const SaladBar = () => {
    var navigate = useNavigate()
    const { currentUser } = useUser()

    useEffect(() => {
        if (!currentUser.isAuthSuccessful) {
            navigate('/login?r=/saladbar')
        }
    },[])

    return (
        <h2>Salad Bar</h2>
    )
}

export default SaladBar