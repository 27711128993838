import { addDays } from "date-fns"
import React, { useEffect, useState } from "react"
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Form, Label, Input } from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const TrackerShareModal = ({isOpen, toggle, onSendClick, onCancelClick}) => {
    const [sendEmails, setSendEmails] = useState(true)
    const [emailList, setEmailList] = useState('')
    const [customMessage, setCustomMessage] = useState('')
    const [description, setDescription] = useState('')
    const [neverExpires, setNeverExpires] = useState(false)
    const [expiresAt, setExpiresAt] = useState(addDays(new Date(), 90))
    const [isSaveDisabled, setIsSaveDisabled] = useState(true)

    const handleSendClick = () => {
        const trackerSettings = {
            sendEmails,
            emailList,
            customMessage,
            description,
            neverExpires,
            expiresAt
        }
        onSendClick(trackerSettings)
    }

    useEffect(() => {
        let disabled = sendEmails && !emailList && !description
        if (!neverExpires && !disabled) {
            disabled = expiresAt <= new Date()
        }
        setIsSaveDisabled(disabled);
    },[sendEmails, emailList, description, neverExpires, expiresAt])

    return (
        <Modal isOpen={isOpen}
            toggle={toggle}>
            <ModalHeader>Share This Calculator</ModalHeader>
            <ModalBody>
                <Form>
                    <Label for='description'>Description</Label>
                    <Input id='description' name='description' value={description} onChange={(e)=>setDescription(e.target.value)} />
                    <Label for='sendemail'>Send to colleagues&nbsp;</Label>
                    <Input id='sendmail' name='sendmail' type="checkbox" checked={sendEmails} onChange={(e) => setSendEmails(e.target.checked)} /><br />
                    {sendEmails &&
                        <span>Enter a comma seperated list of email addresses for exclusive access to your calculator.</span>
                    }
                    {!sendEmails &&
                        <span>Without emails, anyone with the link can view your calculator.</span>
                    }
                    <br />
                    <Input id='emails' name='emails' disabled={!sendEmails} value={emailList} onChange={(e) => setEmailList(e.target.value)} />
                    <Label for='neverexpires'>Link never expires&nbsp;</Label>
                    <Input id='neverexpires' name='neverexpires' type="checkbox" checked={neverExpires} onChange={(e)=>setNeverExpires(e.target.checked)} /><br />
                    <Label for="expiresdate">Link expires at</Label>
                    <DatePicker selected={expiresAt} disabled={neverExpires} onChange={(date) => setExpiresAt(date)} />
                    <Label for='message'>Custom email message (optional)</Label>
                    <Input id='message' name='message' type='textarea' value={customMessage} onChange={(e)=>setCustomMessage(e.target.value)} />
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onCancelClick}>Cancel</Button>
                <Button color="primary" disabled={isSaveDisabled} onClick={handleSendClick}>{sendEmails ? 'Send' : 'Generate'}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default TrackerShareModal