import Home from "./components/Home";
import Login from "./components/Login";
import TeamCalculator from "./components/TeamCalculator";
import Register from "./components/Register";
import SaladBar from "./components/SaladBar";
import Account from "./components/Account";
import ResetPassword from "./components/ResetPassword";
import ForgotPassword from "./components/ForgotPassword";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/teamcalculator',
    element: <TeamCalculator />
  },
  {
    path: '/teamcalculator/:shareid',
    element: <TeamCalculator />
  },
  {
    path: '/saladbar',
    element: <SaladBar />
  },
  {
    path: '/account',
    element: <Account />
  },
  {
    path: '/resetpassword',
    element: <ResetPassword />
  },
  {
    path: '/forgotpassword',
    element: <ForgotPassword />
  }
];

export default AppRoutes;
