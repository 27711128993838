const Files = {
  download: async (url, filename) => {
    var response = await fetch(url)
    if (response.ok) {
      var blob = await response.blob()
      if (blob) {
        const uri = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = uri
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }
    }
  }
}

export default Files